<template>
  <v-app>
    <transition name="route" mode="out-in">
      <router-view />
    </transition>
  </v-app>
</template>

<script>

  export default {
    name: 'App',

    data: () => ({
      //
    }),
  };
</script>
