export default [
	{
		name: 'auth',
		path: '/auth',
		component: () => import('../components/auth'),
	},
	{
		path: '/', component: () => import('../components/layouts/layout-main'), meta: {},
		children: [
			{
				path: '/',
				name: 'home',
				component: ()=>import('../components/home'),
				meta: {api: "home"}
			},
			{
				path: '/places',
				name: 'places',
				component: ()=>import('../components/places'),
				meta: {api: "base"}
			},
			{
				path: '/simulators/:placeId',
				name: 'simulators',
				component: ()=>import('../components/simulators'),
				meta: {api: "simulators"}
			},
			{
				path: '/trainers',
				name: 'trainers',
				component: ()=>import('../components/trainers'),
				meta: {api: "base"}
			},
			{
				path: '/trainers/:id',
				name: 'trainer',
				component: ()=>import('../components/trainer'),
				props: true,
				meta: {api: "trainer"}
			},
			{
				path: '/payment/status',
				name: 'payment-status',
				component: ()=>import('../components/payment-status'),
				meta: {api: "payment-status", query: {orderBankId: "orderId"}}
			},
			{
				path: '/payment/status/:status',
				name: 'payment-status',
				component: ()=>import('../components/payment-status'),
				props: true,
				meta: {api: "payment-status"}
			},
			{
				path: '/profile',
				name: 'profile',
				component: ()=>import('../components/profile'),
				meta: {api: "base"}
			},
			{
				path: '/support',
				name: 'support',
				component: ()=>import('../components/support'),
				meta: {api: "base"}
			},
			{
				path: '/logout',
				name: 'logout',
				component: ()=>import('../components/logout'),
				//meta: {api: "RestrictedController"}
			},
			{
				name: 'terms',
				path: '/terms',
				component: () => import('../components/terms'),
			},
			{
				name: 'policy',
				path: '/policy',
				component: () => import('../components/policy'),
			},
			{
				name: 'contacts',
				path: '/contacts',
				component: () => import('../components/contacts'),
			},
		]
	},
	{
		path: '/error/:code',
		name: 'error',
		component: ()=>import('../components/error'),
		props: true,
	},
];
